@font-face {
  font-family: "Riverside";
  src: local("Riverside"), url(../../fonts/Riverside.otf) format("opentype");
}
@font-face {
  font-family: "Bellada";
  src: local("Bellada"), url(../../fonts/bellada.ttf) format("truetype");
}
@font-face {
  font-family: "LabTop";
  src: local("LabTop"), url(../../fonts/LABTOPB_.ttf) format("truetype");
}

p {
  color: #e5e7eb;
}
.test {
  border: solid white;
  max-width: 40vw;
  border-radius: 10%;
  display: inline-table;
  margin-left: 1%;
  margin-right: 1%;
  height: 600px;
  width: 50%;
}
.centerCard {
  margin-left: 7.5%;
  padding-bottom: 3.75%;
  padding-top: 3.75%;
}
.centerButton {
  margin-left: 30vw;
  border-radius: 20%;
  margin-bottom: 5%;
  margin: 0 auto;
}
.stretch {
  height: 70vh;
}
.center {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.card {
  background-color: #282c34;
  border-radius: 20%;
  height: 650px;
}
@media screen and (max-width: 728px) {
  .test {
    border: solid white;
    border-radius: 10%;
    display: inline-table;
    margin-left: 1%;
    margin-right: 1%;
    height: 600px;
    width: 50%;
  }
}
