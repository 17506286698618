body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #282c34;
  color: #e5e7eb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a.tile header {
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  color: #e5e7eb;
  font-size: 46px;
}
a.tile {
  text-decoration: none;
  color: #e5e7eb;
  cursor: pointer;
}
p {
  color: #e5e7eb;
  margin-top: 10px;
}
h1 {
  font-size: 50px;
}
h2 {
  font-size: 30px;
  color: #e5e7eb;
  background-color: #282c34;
}
header > h1 {
  font-size: 90px;
  margin-bottom: 5px;
}

a:hover {
  color: #ff8374;
  text-decoration: none;
}

@font-face {
  font-family: "Riverside";
  src: local("Riverside"), url(/static/media/Riverside.d1be0223.otf) format("opentype");
}
@font-face {
  font-family: "Bellada";
  src: local("Bellada"), url(/static/media/bellada.32cbf166.ttf) format("truetype");
}
.logo {
  font-size: 64px;
  color: #00a9ea;
  margin-top: 3px;
  margin-bottom: 0px;
  padding: 10px;
  font-family: Riverside;
}
nav {
  display: flex;
  justify-content: space-between;
  background-color: #282c34;
  color: #e5e7eb;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
}

.navdrops {
  color: #ff7260;
}
nav ul {
  list-style: none;
}
nav ul li {
  display: inline-block;
  margin-right: 8px;
}
nav ul li a {
  text-decoration: none;
  color: #ff7260;
  font-size: 30px;
  padding-left: 7%;
}
.subheader {
  background-color: #282c34;
  color: #e5e7eb;
  align-items: left;
  margin: 0000;
  padding-left: 10vw;
  font-size: 40px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #e5e7eb;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
header {
  color: #e5e7eb;
}


.logo {
  font-size: 64px;
  margin-top: 3px;
  margin-bottom: 0px;
  padding: 10px;
  font-family: Riverside;
}


header {
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  font-size: 46px;
}
a {
  text-decoration: none;
  color: #e5e7eb;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 2%;
  color: #e5e7eb;
}
.background {
  background-color: #282c34;
}
.spaceLinks {
  margin-top: 5%;
  margin-bottom: 5%;
}

@font-face {
  font-family: "Riverside";
  src: local("Riverside"), url(/static/media/Riverside.d1be0223.otf) format("opentype");
}
@font-face {
  font-family: "Bellada";
  src: local("Bellada"), url(/static/media/bellada.32cbf166.ttf) format("truetype");
}
@font-face {
  font-family: "LabTop";
  src: local("LabTop"), url(/static/media/LABTOPB_.46ad262c.ttf) format("truetype");
}

.centerAbout {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
  margin-top: 10;
  margin-bottom: 10;
  padding-top: 10;
  padding-bottom: 10;
  color: #e5e7eb;
}
.innerBlurb {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 30px;
  font-family: Futura;
}
img {
  border-radius: 20%;
}
.movein {
  margin-left: 10vw;
  margin-right: 10vw;
  padding-bottom: 10vh;
  padding-top: 5vh;
}
.cleanspace {
  margin-bottom: 0;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

span {
  color: #e5e7eb;
}
p {
  color: #e5e7eb;
}

@font-face {
  font-family: "Riverside";
  src: local("Riverside"), url(/static/media/Riverside.d1be0223.otf) format("opentype");
}
@font-face {
  font-family: "Bellada";
  src: local("Bellada"), url(/static/media/bellada.32cbf166.ttf) format("truetype");
}
@font-face {
  font-family: "LabTop";
  src: local("LabTop"), url(/static/media/LABTOPB_.46ad262c.ttf) format("truetype");
}

p {
  color: #e5e7eb;
}
.test {
  border: solid white;
  max-width: 40vw;
  border-radius: 10%;
  display: inline-table;
  margin-left: 1%;
  margin-right: 1%;
  height: 600px;
  width: 50%;
}
.centerCard {
  margin-left: 7.5%;
  padding-bottom: 3.75%;
  padding-top: 3.75%;
}
.centerButton {
  margin-left: 30vw;
  border-radius: 20%;
  margin-bottom: 5%;
  margin: 0 auto;
}
.stretch {
  height: 70vh;
}
.center {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.card {
  background-color: #282c34;
  border-radius: 20%;
  height: 650px;
}
@media screen and (max-width: 728px) {
  .test {
    border: solid white;
    border-radius: 10%;
    display: inline-table;
    margin-left: 1%;
    margin-right: 1%;
    height: 600px;
    width: 50%;
  }
}


.background{
		background-color: #282c34;
margin-left: 0;
}
a{
	margin-bottom: 5%;
}
.bigSpacing{
	padding-bottom: 10%;
	padding-top: 10%;
}
.faq_center {
  margin: auto 0;
  text-align: center;
  padding: 0 0 0 0;
  font-family: "Futura";
}
h4 {
  font-family: "Futura";
}

