
.background{
		background-color: #282c34;
margin-left: 0;
}
a{
	margin-bottom: 5%;
}
.bigSpacing{
	padding-bottom: 10%;
	padding-top: 10%;
}