header {
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  font-size: 46px;
}
a {
  text-decoration: none;
  color: #e5e7eb;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 2%;
  color: #e5e7eb;
}
.background {
  background-color: #282c34;
}
.spaceLinks {
  margin-top: 5%;
  margin-bottom: 5%;
}
