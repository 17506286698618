@font-face {
  font-family: "Riverside";
  src: local("Riverside"), url(../../fonts/Riverside.otf) format("opentype");
}
@font-face {
  font-family: "Bellada";
  src: local("Bellada"), url(../../fonts/bellada.ttf) format("truetype");
}
@font-face {
  font-family: "LabTop";
  src: local("LabTop"), url(../../fonts/LABTOPB_.ttf) format("truetype");
}

.centerAbout {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
  margin-top: 10;
  margin-bottom: 10;
  padding-top: 10;
  padding-bottom: 10;
  color: #e5e7eb;
}
.innerBlurb {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 30px;
  font-family: Futura;
}
img {
  border-radius: 20%;
}
.movein {
  margin-left: 10vw;
  margin-right: 10vw;
  padding-bottom: 10vh;
  padding-top: 5vh;
}
.cleanspace {
  margin-bottom: 0;
  padding-top: 5vh;
  padding-bottom: 5vh;
}
