body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #282c34;
  color: #e5e7eb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a.tile header {
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  color: #e5e7eb;
  font-size: 46px;
}
a.tile {
  text-decoration: none;
  color: #e5e7eb;
  cursor: pointer;
}
p {
  color: #e5e7eb;
  margin-top: 10px;
}
h1 {
  font-size: 50px;
}
h2 {
  font-size: 30px;
  color: #e5e7eb;
  background-color: #282c34;
}
header > h1 {
  font-size: 90px;
  margin-bottom: 5px;
}

a:hover {
  color: #ff8374;
  text-decoration: none;
}
